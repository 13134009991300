@font-face {
  font-family: 'EuclidCircularB';
  src: url('https://s3.amazonaws.com/tippy.fonts/EuclidCircularB-Light-WebXL.woff2') format('woff2'),
    url('https://s3.amazonaws.com/tippy.fonts/EuclidCircularB-Light-WebXL.woff') format('woff');
  font-weight: 300;
}

@font-face {
  font-family: 'EuclidCircularB';
  src: url('https://s3.amazonaws.com/tippy.fonts/EuclidCircularB-Regular-WebXL.woff2')
      format('woff2'),
    url('https://s3.amazonaws.com/tippy.fonts/EuclidCircularB-Regular-WebXL.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'EuclidCircularB';
  src: url('https://s3.amazonaws.com/tippy.fonts/EuclidCircularB-Medium-WebXL.woff2')
      format('woff2'),
    url('https://s3.amazonaws.com/tippy.fonts/EuclidCircularB-Medium-WebXL.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'EuclidCircularB';
  src: url('https://s3.amazonaws.com/tippy.fonts/EuclidCircularB-Semibold-WebXL.woff2')
      format('woff2'),
    url('https://s3.amazonaws.com/tippy.fonts/EuclidCircularB-Semibold-WebXL.woff') format('woff');
  font-weight: 600;
}

@font-face {
  font-family: 'EuclidCircularB';
  src: url('https://s3.amazonaws.com/tippy.fonts/EuclidCircularB-Bold-WebXL.woff2') format('woff2'),
    url('https://s3.amazonaws.com/tippy.fonts/EuclidCircularB-Bold-WebXL.woff') format('woff');
  font-weight: 700;
}

@font-face {
  font-family: 'EuclidCircularB';
  src: url('https://s3.amazonaws.com/tippy.fonts/EuclidCircularB-LightItalic-WebXL.woff2')
      format('woff2'),
    url('https://s3.amazonaws.com/tippy.fonts/EuclidCircularB-LightItalic-WebXL.woff')
      format('woff');
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: 'EuclidCircularB';
  src: url('https://s3.amazonaws.com/tippy.fonts/EuclidCircularB-RegularItalic-WebXL.woff2')
      format('woff2'),
    url('https://s3.amazonaws.com/tippy.fonts/EuclidCircularB-RegularItalic-WebXL.woff')
      format('woff');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'EuclidCircularB';
  src: url('https://s3.amazonaws.com/tippy.fonts/EuclidCircularB-MediumItalic-WebXL.woff2')
      format('woff2'),
    url('https://s3.amazonaws.com/tippy.fonts/EuclidCircularB-MediumItalic-WebXL.woff')
      format('woff');
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: 'EuclidCircularB';
  src: url('https://s3.amazonaws.com/tippy.fonts/EuclidCircularB-SemiboldItalic-WebXL.woff2')
      format('woff2'),
    url('https://s3.amazonaws.com/tippy.fonts/EuclidCircularB-SemiboldItalic-WebXL.woff')
      format('woff');
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: 'EuclidCircularB';
  src: url('https://s3.amazonaws.com/tippy.fonts/EuclidCircularB-BoldItalic-WebXL.woff2')
      format('woff2'),
    url('https://s3.amazonaws.com/tippy.fonts/EuclidCircularB-BoldItalic-WebXL.woff') format('woff');
  font-style: italic;
  font-weight: 700;
}

/** MUI Datagrid styles */
.MuiPaper-root.MuiDataGrid-paper {
  border-radius: 16px;
  box-shadow: 0px 8px 24px 0px #0000003d;
}

.MuiDataGrid-menu .MuiPaper-root {
  border-radius: 16px;
  box-shadow: 0px 8px 24px 0px #0000003d;
  padding: 16px;
}
.MuiPaper-root.MuiPopover-paper {
  box-shadow: 0px 8px 24px 0px #0000003d;
}

.MuiAutocomplete-paper {
  box-shadow: 0px 8px 24px 0px #0000003d !important;
}
